import React from 'react'

const Banner = () => {
  return (
    <div className='my-10 text-center'>
        <h1 className='text-3xl tracking-wide mb-1'>Currency Prices Live</h1>
        <h5 className='text-lg tracking-wide'>Top Coins by Market Cap</h5>
    </div>
  )
}

export default Banner
