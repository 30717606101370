import React from 'react'
import Banner from './Banner'
import Table from './Table'

const Main = () => {
  return (
    <div className='container mx-auto'>
        <Banner />
        <Table />
    </div>
  )
}

export default Main
