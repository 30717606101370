import React, { useEffect, useState } from 'react'
import {io} from "socket.io-client";
import {FaSortUp,FaSortDown} from 'react-icons/fa';
import flag from '../images/icons/taiwan-flag.png';

const Table = () => {
  const [data,setData] = useState([]);
  useEffect(()=>{
    const socket = io(`${process.env.REACT_APP_HOST}/watch`);
    socket.connect();
    socket.on("watch", res => {
        setData(res);
    });
    return () => {
        socket.disconnect();
    };
  },[]);


  const chunk = num => {
        if(num){
            let str = num.toString().split('.');
            if (str[0].length >= 4) {
                str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
            }
            if (str[1] && str[1].length >= 5) {
                str[1] = str[1].replace(/(\d{3})/g, '$1 ');
            }
            return str.join('.');
        }
  };
 
  let calcUnit = function (num) {
    if(num){
        let parts = num.split(",");
        return parts.length > 1 ? (["T", "M", "B"][parts.length-2]) : parts[0];
    }
  };
 
  return (
    <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-[980px] lg:w-full text-left text-gray-500">
            <thead className="text-sm capitalize text-gray-300 bg-gray-700">
                <tr>
                    <th scope="col" className="py-3 px-6 w-10">
                        Coin
                    </th>
                    <th scope="col" className="py-3 px-6">
                        Price
                    </th>
                    <th scope="col" className="py-3 px-6">
                        Market Cap
                    </th>
                    <th scope="col" className="py-3 px-6">
                        Volume 24h
                    </th>
                    <th scope="col" className="py-3 px-6">
                        All-time High
                    </th>
                    <th scope="col" className="py-3 px-6">
                        1h
                    </th>
                    <th scope="col" className="py-3 px-6">
                        24h
                    </th>
                    <th scope="col" className="py-3 px-6">
                        Weekly
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    data.length > 0 &&
                    data.map((value,i) => 
                        <tr className="border-b bg-gray-800 border-gray-700 font-bold" key={i}>
                            <th scope="row" className="py-4 px-6 text-base whitespace-nowrap text-white">
                                <div className='
                                    flex
                                    items-center
                                '>
                                    <img src={
                                        (value.code === "BTC" && flag) ||
                                        (value.code === "ETH" && flag) ||
                                        value.png32
                                    } className="mr-2" alt="coin" />
                                    <div>
                                        {
                                            (value.code === "BTC" && "Taiwan BTC") ||
                                            (value.code === "ETH" && "Taiwan ETH") ||
                                            value.code
                                        }
                                        <span className='
                                            block
                                            text-xs
                                            text-slate-400
                                        '>
                                        {
                                            (value.code === "BTC" && "Taiwan Bitcoin") ||
                                            (value.code === "ETH" && "Taiwan Ethereum") ||
                                            value.name
                                        }
                                        </span>
                                    </div>
                                </div>
                            </th>
                            <td className="py-4 px-6 text-base text-white">
                                ${
                                   value.code === 'BTC' && value.rate
                                }
                                {
                                   value.code === 'ETH' && value.rate
                                }
                                {
                                   value.code === 'USDT' && (Math.round(value.rate * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'USDC' && (Math.round(value.rate * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'BNB' && (Math.round(value.rate * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'XRP' && (Math.round(value.rate * 1000000) / 1000000).toFixed(6)
                                }
                                {
                                   value.code === 'BUSD' && (Math.round(value.rate * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'DOGE' && (Math.round(value.rate * 1000000) / 1000000).toFixed(6)
                                }
                                {
                                   value.code === 'DAI' && (Math.round(value.rate * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'ADA' && (Math.round(value.rate * 1000000) / 1000000).toFixed(6)
                                }
                            </td>
                            <td className="py-4 px-6 text-base text-white">
                                ${
                                   value.code === 'BTC' && `${(Math.round(value.cap) / 1000000000).toFixed(2)} ${calcUnit(chunk(value.cap))}`
                                }
                                {
                                   value.code === 'ETH' && `${(Math.round(value.cap) / 1000000000).toFixed(2)} ${calcUnit(chunk(value.cap))}`
                                }
                                {
                                   value.code === 'USDT' && `${(Math.round(value.cap) / 1000000000).toFixed(3)} ${calcUnit(chunk(value.cap))}`
                                }
                                {
                                   value.code === 'USDC' && `${(Math.round(value.cap) / 1000000000).toFixed(3)} ${calcUnit(chunk(value.cap))}` 
                                }
                                {
                                   value.code === 'BNB' && `${(Math.round(value.cap) / 1000000000).toFixed(3)} ${calcUnit(chunk(value.cap))}`
                                }
                                {
                                   value.code === 'XRP' && `${(Math.round(value.cap) / 1000000000).toFixed(3)} ${calcUnit(chunk(value.cap))}`
                                }
                                {
                                   value.code === 'BUSD' && `${(Math.round(value.cap) / 1000000000).toFixed(3)} ${calcUnit(chunk(value.cap))}`
                                }
                                {
                                   value.code === 'DOGE' && `${(Math.round(value.cap) / 1000000).toFixed(1)} ${calcUnit(chunk(value.cap))}` 
                                }
                                {
                                   value.code === 'DAI' && `${(Math.round(value.cap) / 1000000000).toFixed(4)} ${calcUnit(chunk(value.cap))}`
                                }
                                {
                                   value.code === 'ADA' && `${(Math.round(value.cap) / 1000000000).toFixed(4)} ${calcUnit(chunk(value.cap))}`
                                }
                            </td>
                            <td className="py-4 px-6 text-base text-white">
                                ${
                                   value.code === 'BTC' && `${(Math.round(value.volume) / 1000000000).toFixed(3)} ${calcUnit(chunk(value.volume))}`
                                }
                                {
                                   value.code === 'ETH' && `${(Math.round(value.volume) / 1000000000).toFixed(3)} ${calcUnit(chunk(value.volume))}`
                                }
                                {
                                   value.code === 'USDT' && `${(Math.round(value.volume) / 1000000000).toFixed(2)} ${calcUnit(chunk(value.volume))}`
                                }
                                {
                                   value.code === 'USDC' && `${(Math.round(value.volume) / 1000000).toFixed(1)} ${calcUnit(chunk(value.volume))}` 
                                }
                                {
                                   value.code === 'BNB' && `${(Math.round(value.volume) / 1000000).toFixed(1)} ${calcUnit(chunk(value.volume))}`
                                }
                                {
                                   value.code === 'XRP' && `${(Math.round(value.volume) / 1000000).toFixed(1)} ${calcUnit(chunk(value.volume))}`
                                }
                                {
                                   value.code === 'BUSD' && `${(Math.round(value.volume) / 1000000000).toFixed(3)} ${calcUnit(chunk(value.volume))}`
                                }
                                {
                                   value.code === 'DOGE' && `${(Math.round(value.volume) / 1000000).toFixed(1)} ${calcUnit(chunk(value.volume))}` 
                                }
                                {
                                   value.code === 'DAI' && `${(Math.round(value.volume) / 1000000).toFixed(2)} ${calcUnit(chunk(value.volume))}`
                                }
                                {
                                   value.code === 'ADA' && `${(Math.round(value.volume) / 1000000).toFixed(1)} ${calcUnit(chunk(value.volume))}`
                                }
                            </td>
                            <td className="py-4 px-6 text-base text-white">
                                ${
                                   value.code === 'BTC' && (Math.round(value.allTimeHighUSD * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'ETH' && (Math.round(value.allTimeHighUSD * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'USDT' && (Math.round(value.allTimeHighUSD * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'USDC' && (Math.round(value.allTimeHighUSD * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'BNB' && (Math.round(value.allTimeHighUSD * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'XRP' && (Math.round(value.allTimeHighUSD * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'BUSD' && (Math.round(value.allTimeHighUSD * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'DOGE' && (Math.round(value.allTimeHighUSD * 1000000) / 1000000).toFixed(6)
                                }
                                {
                                   value.code === 'DAI' && (Math.round(value.allTimeHighUSD * 100) / 100).toFixed(2)
                                }
                                {
                                   value.code === 'ADA' && (Math.round(value.allTimeHighUSD * 100) / 100).toFixed(2)
                                }
                            </td>
                            <td className="py-4 px-6 text-base text-white">
                                <span className={`
                                    flex
                                    items-center
                                    text-${
                                        (((value.delta.hour - 1) * 100).toFixed(2) > 0 && "green-500") ||
                                        (((value.delta.hour - 1) * 100).toFixed(2) < 0 && "red-500")
                                    }
                                `}>
                                    {
                                        ((value.delta.hour - 1) * 100) == 0 ? '0':
                                        ((value.delta.hour - 1) * 100).toFixed(2)
                                    }%
                                    {
                                        (((value.delta.hour - 1) * 100).toFixed(2) > 0 &&
                                        <FaSortUp className="mt-2" />) ||
                                        (((value.delta.hour - 1) * 100).toFixed(2) < 0 &&
                                        <FaSortDown className="mb-2" />)
                                    }
                                </span>
                            </td>
                            <td className="py-4 px-6 text-base text-white">
                                <span className={`
                                    flex
                                    items-center
                                    text-${
                                        (((value.delta.day - 1) * 100).toFixed(2) > 0 && "green-500") ||
                                        (((value.delta.day - 1) * 100).toFixed(2) < 0 && "red-500")
                                    }
                                `}>
                                    {
                                        ((value.delta.day - 1) * 100) == 0 ? '0':
                                        ((value.delta.day - 1) * 100).toFixed(2)
                                    }%
                                    {
                                        (((value.delta.day - 1) * 100).toFixed(2) > 0 &&
                                        <FaSortUp className="mt-2" />) ||
                                        (((value.delta.day - 1) * 100).toFixed(2) < 0 &&
                                        <FaSortDown className="mb-2" />)
                                    }
                                </span>
                            </td>
                            <td className="py-4 px-6 text-base text-white">
                                <span className={`
                                    flex
                                    items-center
                                    text-${
                                        (((value.delta.week - 1) * 100).toFixed(2) > 0 && "green-500") ||
                                        (((value.delta.week - 1) * 100).toFixed(2) < 0 && "red-500")
                                    }
                                `}>
                                    {
                                        ((value.delta.week - 1) * 100) == 0 ? '0':
                                        ((value.delta.week - 1) * 100).toFixed(2)
                                    }%
                                    {
                                        (((value.delta.week - 1) * 100).toFixed(2) > 0 &&
                                        <FaSortUp className="mt-2" />) ||
                                        (((value.delta.week - 1) * 100).toFixed(2) < 0 &&
                                        <FaSortDown className="mb-2" />)
                                    }
                                </span>
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </table>
    </div>
  )
}

export default Table
